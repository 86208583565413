import logo from "./assets/img/logo.png";
import launchappBtn from "./assets/img/launchapp-btn.png";
import introTitle from "./assets/img/intro-title.png";
import introImg from "./assets/img/intro-img.png";
import joinBtn from "./assets/img/join-btn.png";
import joinBtn2 from "./assets/img/join-2-btn.png";
import buynowBtn from "./assets/img/buynow-btn.png";
import viewcontract from "./assets/img/viewcontract-btn.png";
import missionGif from "./assets/img/Mission.gif";
import slidesGif from "./assets/img/Slides.gif";
import tokenomicsGif from "./assets/img/Tokenomics.gif";
import communityGif from "./assets/img/Community.gif";
import communityTitle from "./assets/img/community-title.png";
import tokenomicsTitle from "./assets/img/tokenomics-title.png";
import missionTitle from "./assets/img/mission-title.png";
import aboutLogo from "./assets/img/about-logo.png";
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <header className="bg-[url('./assets/img/bg-intro.png')] bg-cover">
        <div className="container mx-auto px-2 lg:px-5">
          <div className="py-2 flex flex-col lg:flex-row gap-2 justify-between items-center">
            <div className="max-w-52">
              <img src={logo} alt="" />
            </div>
            <div className="flex gap-3 lg:gap-10 capitalize lg:uppercase">
              <a href="#about">About</a>
              <a href="#tokenomics">Tokenomics</a>
              <a
                href="https://twitter.com/XORAIToken"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1"
              >
                <svg
                  // className="hidden lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M0.0486663 0L7.77043 10.4796L0 19H1.74895L8.55211 11.5401L14.0487 19H20L11.8436 7.93106L19.0764 0H17.3274L11.0623 6.87022L6 0H0.0486663ZM2.62057 1.30748H5.35459L17.4278 17.6925H14.6937L2.62057 1.30748Z"
                    fill="white"
                  />
                </svg>
                <span>Twitter</span>
              </a>
              <a
                href="https://t.me/XORAI_Portal"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1"
              >
                <svg
                  // className="hidden lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="18"
                  viewBox="0 0 23 19"
                  fill="none"
                >
                  <path
                    d="M1.41007 8.27456L15.1104 2.63503C16.4628 2.04758 21.0492 0.167738 21.0492 0.167738C21.0492 0.167738 23.166 -0.654693 22.9896 1.34264C22.9308 2.16507 22.4604 5.04358 21.99 8.15707L20.52 17.38C20.52 17.38 20.4024 18.7312 19.4028 18.9662C18.4032 19.2011 16.7568 18.1437 16.4628 17.9087C16.2276 17.7325 12.0528 15.089 10.524 13.7966C10.1124 13.4441 9.64203 12.7392 10.5828 11.9168C12.6996 9.97816 15.228 7.56962 16.7568 6.04224C17.4624 5.3373 18.168 3.69244 15.228 5.68977L6.93724 11.2706C6.93724 11.2706 5.99645 11.858 4.23246 11.3293C2.46847 10.8006 0.410477 10.0957 0.410477 10.0957C0.410477 10.0957 -1.00072 9.21448 1.41007 8.27456Z"
                    fill="white"
                  />
                </svg>
                <span>Telegram</span>
              </a>
            </div>
            <a className="max-w-60 transition-all hover:opacity-80">
              <img src={launchappBtn} alt="" />
            </a>
          </div>

          <div className="py-10">
            <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-10">
              <div className="mx-auto lg:mx-0 max-w-[616px] xl:mt-24">
                <div>
                  <img src={introTitle} alt="" />
                </div>
                <p className="mt-12 text-lg text-center lg:text-left">
                  Discover the future of video production with XORAI, the
                  pioneering token project at the intersection of blockchain
                  technology and artificial intelligence.
                </p>
                <div className="flex justify-center lg:justify-start gap-2 mt-5">
                  <a
                    href="https://t.me/XORAI_Portal"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lg:-ml-7 transition-all hover:opacity-80"
                  >
                    <img
                      className="h-[70px] xl:h-[80px] 2xl:h-[90px]"
                      src={joinBtn}
                      alt=""
                    />
                  </a>
                  <a className="transition-all hover:opacity-80">
                    <img
                      className="h-[70px] xl:h-[80px] 2xl:h-[90px]"
                      src={viewcontract}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="max-w-[619px]">
                <img src={introImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section
          id="about"
          className="py-12 px-2 lg:px-8 bg-[url('./assets/img/bg-about.png')] bg-cover"
        >
          <div className="py-10 lg:py-24 px-4 lg:px-16 rounded-3xl bg-[url('./assets/img/bg-mission.png')] bg-cover">
            <div className="container mx-auto px-2 lg:px-5 grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10">
              <div
                data-aos="fade-right"
                className="max-w-xl box-shadow-custom rounded-xl bg-[#030628] border-[1px] border-[#3825FF] p-4"
              >
                <div>
                  <img src={missionGif} alt="" />
                </div>
              </div>

              <div
                data-aos="fade-left"
                className="max-w-xl flex flex-col justify-center"
              >
                <div className="max-w-[230px] lg:max-w-[323px]">
                  <img src={missionTitle} alt="" />
                </div>
                <p className="mt-5">
                  XORAI is designed to democratize video creation, offering
                  unparalleled access to cutting-edge AI tools that empower
                  creators, businesses, and enthusiasts to bring their visions
                  to life with efficiency, creativity, and ease.
                  <br />
                  <br />
                  XORAI Can Generate Videos Up To A Minute Long While
                  Maintaining Visual Quality And Adherence To The User’s Prompt.
                </p>
              </div>

              <div
                data-aos="fade-right"
                className="max-w-xl flex flex-col justify-center"
              >
                <div className="max-w-[470px]">
                  <img src={communityTitle} alt="" />
                </div>
                <p className="mt-5">
                  Welcome to the heartbeat of XORAI - our dedicated Telegram
                  community! A place where token holders, enthusiasts, and the
                  curious minds of the AI video creation world come together.
                  Dive into real-time discussions, gain exclusive insights, and
                  be part of a community that's shaping the future of video
                  production with blockchain and AI.
                </p>
                <a
                  href="https://t.me/XORAI_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full transition-all hover:opacity-80"
                >
                  <img className="min-h-[65px]" src={joinBtn2} alt="" />
                </a>
              </div>

              <div
                data-aos="fade-left"
                className="max-w-xl box-shadow-custom rounded-xl bg-[#030628] border-[1px] border-[#3825FF] p-4"
              >
                <div>
                  <img src={communityGif} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="max-w-[1196px] mx-auto px-2 lg:px-5 mt-20"
          >
            <div className="text-center">
              <div className="max-w-[250px] lg:max-w-[511px] mx-auto">
                <img src={aboutLogo} alt="" />
              </div>
              <p>
                The model has a deep understanding of language, enabling it to
                accurately interpret prompts and generate compelling characters
                that express vibrant emotions. Sora can also create multiple
                shots within a single generated video that accurately persist
                characters and visual style.
              </p>

              <div className="mt-10 lg:mt-16 w-full box-shadow-custom rounded-xl bg-[#030628] border-[1px] border-[#3825FF] p-4">
                <div>
                  <img src={slidesGif} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tokenomics" className="py-12 px-2 lg:px-8">
          <div className="py-10 lg:py-24 px-4 lg:px-16 rounded-3xl bg-[url('./assets/img/bg-tokenomics.png')] bg-cover">
            <div className="container mx-auto px-2 lg:px-5 grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10">
              <div
                data-aos="fade-right"
                className="max-w-xl box-shadow-custom rounded-xl bg-[#030628] border-[1px] border-[#3825FF] p-4"
              >
                <div className="h-full">
                  <img
                    className="h-full object-cover"
                    src={tokenomicsGif}
                    alt=""
                  />
                </div>
              </div>

              <div
                data-aos="fade-left"
                className="max-w-xl flex flex-col justify-center"
              >
                <div className="max-w-[488px]">
                  <img src={tokenomicsTitle} alt="" />
                </div>
                <p className="mt-5">
                  Built On A Robust Blockchain Foundation, XORAI Ensures
                  Transparency And Security For All Transactions.
                  <br />
                  <br />
                  The XORAI Token Holder Community On Telegram Is More Than Just
                  A Group—It's Where The Heart Of Our Project Beats. This
                  Exclusive Space Is Designed For You To Connect With Fellow
                  Visionaries, Enthusiasts, And The Minds Behind XORAI.
                </p>
                <div className="mt-5">
                  <p className="font-bold text-2xl">
                    Buy/Sell Tax: <span className="text-[#25FFA3]">0%</span>-
                    <span className="text-[#FF258E]">0%</span>
                  </p>
                </div>
                <a className="transition-all hover:opacity-80">
                  <img className="min-h-[65px]" src={buynowBtn} alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="py-2">
        <div className="container mx-auto px-2 lg:px-5">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="max-w-[200px] lg:max-w-[243px]">
              <img src={aboutLogo} alt="" />
            </div>
            <div className="flex text-sm gap-3 lg:gap-10 capitalize lg:uppercase">
              <a href="#about">About</a>
              <a href="#tokenomics">tokenomics</a>
              <a
                href="https://twitter.com/XORAIToken"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M0.0486663 0L7.77043 10.4796L0 19H1.74895L8.55211 11.5401L14.0487 19H20L11.8436 7.93106L19.0764 0H17.3274L11.0623 6.87022L6 0H0.0486663ZM2.62057 1.30748H5.35459L17.4278 17.6925H14.6937L2.62057 1.30748Z"
                    fill="white"
                  />
                </svg>
                <span>Twitter</span>
              </a>
              <a
                href="https://t.me/XORAI_Portal"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="18"
                  viewBox="0 0 23 19"
                  fill="none"
                >
                  <path
                    d="M1.41007 8.27456L15.1104 2.63503C16.4628 2.04758 21.0492 0.167738 21.0492 0.167738C21.0492 0.167738 23.166 -0.654693 22.9896 1.34264C22.9308 2.16507 22.4604 5.04358 21.99 8.15707L20.52 17.38C20.52 17.38 20.4024 18.7312 19.4028 18.9662C18.4032 19.2011 16.7568 18.1437 16.4628 17.9087C16.2276 17.7325 12.0528 15.089 10.524 13.7966C10.1124 13.4441 9.64203 12.7392 10.5828 11.9168C12.6996 9.97816 15.228 7.56962 16.7568 6.04224C17.4624 5.3373 18.168 3.69244 15.228 5.68977L6.93724 11.2706C6.93724 11.2706 5.99645 11.858 4.23246 11.3293C2.46847 10.8006 0.410477 10.0957 0.410477 10.0957C0.410477 10.0957 -1.00072 9.21448 1.41007 8.27456Z"
                    fill="white"
                  />
                </svg>
                <span>Telegram</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
